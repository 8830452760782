
import { computed, defineComponent, ref, onMounted, getCurrentInstance, watchEffect, onBeforeMount } from "vue";

import { useStore } from "vuex";
// import $ from 'jquery';
import { useRoute, useRouter } from "vue-router";
import CustomerService from "@/core/services/car/CustomerService";
import MasterService from "@/core/services/car/MasterService";
import Multiselect from "@vueform/multiselect";
import UserService from "@/core/services/UserService";
import { hideModal } from "@/core/helpers/dom";
import ExistCustomer from '@/components/modals/forms/ExistCustomer.vue';
import router from "@/router";
import { validation, ql } from '@/store/stateless/store';
import { Mutations } from "@/store/enums/StoreEnums";
import Customer from "@/core/services/car/NEW/Customer"
import { useCheckPhoneNumber} from '@/store/composable/Customer'


interface IQuickCustomer {
  name: string;
  email: string;
  phone_country_code:string,
  phone_number: string;
  lead_source: string;
  salesAgents: string;
  is_car: number;
}
// interface KTCreateCustomer extends Customer {}

export default defineComponent({
  name: "add-customer-modal",
  components: { Multiselect, ExistCustomer },
  setup() {
    const formRef = ref<null | HTMLFormElement>(null);
    const addCustomerModalRef = ref<null | HTMLElement>(null);
    const inst = getCurrentInstance()
    const route = useRoute();

    const loading = ref<boolean>(false);
    // const isViewed = ref<boolean>(true);
    let formData = ref<IQuickCustomer>({
      name: "",
      email: "",
      phone_country_code:"+971",
      phone_number: "",
      lead_source: "skye",
      salesAgents: "",
      is_car: 1
    });
    let isFormValid = ref<boolean>(true);
    // const formData = ref({});
    const store = useStore();

    onMounted(() => {

      // setTimeout(() => {
      //   const nameRef:any = inst?.refs.nameRef
      //   nameRef.focus()
      // }, 1000);
      const isSalesAgents = [2, 9, 13].includes(currentUser.value.role_id)
      if(isSalesAgents) {
        formData.value.salesAgents = currentUser.value.id
      }
      if(ql.isOpen)
      {
        MasterService.getCountryCodes();
        // UserService.getDomSalesAgentsManagerTeamLeader();
        MasterService.getLeadSources();
      }
   
    });

    const customRules = ref({
      name: {
        required: true,
        message: "Please enter name",
        trigger: "change",
        show: false,
      },

      email: {
        required: true,
        message: "Please enter email",
        trigger: "change",
        show: false,
      },

      phone_country_code: {
        show: false,
        message: "Please select country code",
      },
      phone_number: {
        required: true,
        message: "Phone Number is required",
        trigger: "change",
        show: false,
        length_message:"Phone number should be greater than 4 digits",
      },

      lead_source: {
        required: true,
        message: "Please select lead source",
        trigger: "change",
        show: false,
      },
    });
    const resetFormValue = () => {
      clearIt()
      ql.isOpen = false
      formData.value.name = "";
      formData.value.email = "";
      formData.value.phone_country_code = "+971";
      formData.value.phone_number = "";
      formData.value.lead_source = "skye";
      const isSalesAgents = [2, 9, 13].includes(currentUser.value.role_id)
      if(isSalesAgents) {
        formData.value.salesAgents = currentUser.value.id
      }else{
        formData.value.salesAgents = "";
      }
      customRules.value.name.show = false;
      customRules.value.email.show = false;
      customRules.value.phone_number.show = false;
      customRules.value.lead_source.show = false;
      CustomerService.resetQlStatus()
    };

    const resetValidation = () => {
      customRules.value.name.show = false;
      customRules.value.email.show = false;
      customRules.value.phone_number.show = false;
      customRules.value.lead_source.show = false;
    }
    const submit = () => {
      resetValidation();
      if (
        formData.value.name.trim() == "" ||
        (formData.value.email.trim() != "" && !validation.validateEmail(formData.value.email)) ||
        formData.value.phone_country_code.trim() == "" ||
        formData.value.phone_number.trim() == "" ||
        formData.value.lead_source == ""
      ) {
        isFormValid.value = false;
        
      } else {
        isFormValid.value = true;
      }

      validateForm();
      
      if (formRef.value) {
        // make API call for adding customer
        if (isFormValid.value) {
          loading.value = true;
          CustomerService.addQuickCustomer(formData.value)
          .then((res) => {
            loading.value = false;
            resetFormValue();
            const nameRef:any = inst?.refs.nameRef
            nameRef.focus()
            clearIt()
          })
          .catch((err) => {
            loading.value = false;
            // console.log({err})
          })
          
        } else {
          // validateForm();
          focusField()
          loading.value = false;
        }
      } else {
        loading.value = false;
      }

      // formRef.value.validate((valid) => {
      //   if (valid) {
      //     loading.value = true;

      //     setTimeout(() => {
      //       loading.value = false;
      //     }, 2000);
      //   } else {
      //     return false;
      //   }
      // });
    };
    const validateForm = () => {
      let singleCount = formData.value.name.match(/[']/g)
      let minusCount = formData.value.name.match(/[-]/g)

      let singleCountChecker = true;
      let minusCountChecker = true;

      if(singleCount) {
          singleCountChecker = singleCount.length > 1 ? false : true
      }
      if(minusCount) {
          minusCountChecker = minusCount.length > 1 ? false : true
      }
      if(formData.value.phone_number.length < 8 || formData.value.phone_number.length > 10) {
        customRules.value.phone_number.message = 'Please enter at least 8 digits.';
        customRules.value.phone_number.show = true;
        isFormValid.value = false;
        
      }
      if(!singleCountChecker || !minusCountChecker) {
        customRules.value.name.message = `Only character a-z, - and ' allowed`;
        customRules.value.name.show = true;
        isFormValid.value = false;
      }  
      if (formData.value.name.trim() == "") {
        customRules.value.name.show = true;
        isFormValid.value = false;
      } 
      if(formData.value.name && formData.value.name.length < 3) {
        customRules.value.name.message = 'Name must not be less than 3 characters';
        customRules.value.name.show = true;
        isFormValid.value = false;
      }

      // if (formData.value.email.trim() == "") {
      //   customRules.value.email.show = true;
      //   isFormValid.value = false;
      // }
      // else 
      if (formData.value.email.trim() != "" && !validation.validateEmail(formData.value.email)) {
        customRules.value.email.show = true;
        customRules.value.email.message = 'Please enter valid email address';
        isFormValid.value = false;
      }
      else {
         customRules.value.email.show = false;
      }
      if (formData.value.phone_number == "") {
        customRules.value.phone_number.message = 'Please enter phone number';
        customRules.value.phone_number.show = true;
        isFormValid.value = false;
      }
      if(formData.value.phone_number && formData.value.phone_number.length < 8) {
        customRules.value.phone_number.message = 'Phone number must not be less than 8 digits';
        customRules.value.phone_number.show = true;
        isFormValid.value = false;
      }
      if (formData.value.lead_source == "" || formData.value.lead_source == null) {
        customRules.value.lead_source.show = true;
        isFormValid.value = false;
      }

      if (
        formData.value.phone_country_code == null ||
        formData.value.phone_country_code == ""
      ) {
        customRules.value.phone_country_code.show = true;
        isFormValid.value = false;
      }
    };
    let countryCodeList = computed(() => {
      return store.getters.getCountryCode;
    });

    let countryCodes = ref({
      data: [],
      value:"+971",
      options: countryCodeList,
      searchable: true,
      createTag: false,
    });
    const onCountryCodeChange = (event) => {
      formData.value.phone_country_code = event;
    };

    // const checkPhoneNo = () => {
    //   if(formData.value.phone_number) {
    //     // CustomerService.checkExistNumber({
    //     Customer.checkExistNumber({
    //       phoneNo: formData.value.phone_number,
    //       country: formData.value.phone_country_code
    //     })
    //   }
    // }

    // BND-1806
    async function checkPhoneNo() {
      if(formData.value.phone_number) {
        const response = await useCheckPhoneNumber({ phoneNo: formData.value.phone_number, country: formData.value.phone_country_code })
        if(response.is_car == 1) store.commit('SET_EXIST_PHONE_NUMBER', response)
        else {
          // formData.value.name = response.name
          // formData.value.email = response.email
        }
        store.commit('SET_QL_ENABLE', false)
      }
    }

    const clearIt = () => {
      formData.value.phone_number = '';
      Customer.clearExistCustomer();
    }

    const redirectIt = (id: number, redirectType: string) => {
      let path = ''
      let routerData:any= {}
      if(redirectType === 'customerDetails') path = 'customer-details'
      if(redirectType === 'addCar') path = 'new-car-quote'
      if(redirectType === 'addBike') path = 'customer-add-new-bike'
      if(redirectType === 'addCar') {
        routerData = router.resolve({name: path, params: {driverDetailsId: id}, query: {leadsAndTasks: 'true'}})
      } else {
        routerData = router.resolve({name: path, params: {customerId: id}, query: {leadsAndTasks: 'true'}})
      }
      window.open(routerData.href, '_blank');
      setViewQuickLead()
      clearIt()
    };

    const currentUser = computed(() => {
      return store.getters.currentUser;
    })

    let leadSourcesList = computed(() => {
      return store.getters.getLeadSourcesList;
    });
    

    let leadSources = ref({
      data: [],
      value:"skye",
      options: leadSourcesList,
      searchable: true,
      createTag: false,
    });

    const onLeadSourceChange = (event) => {
      // formData.value.lead_source = event;
    };

    const onLeadSourceClear = () => {
      formData.value.lead_source = 'skye';
    };

    const focusField = () => {
      const { name, phone_country_code, phone_number, lead_source } = customRules.value

      if(name.show) {
        const nameRef:any = inst?.refs.nameRef
        nameRef.focus()
      }

      if(!name.show && phone_country_code.show) {
        const countryCodeRef:any = inst?.refs.countryCodeRef
        countryCodeRef.focusInput()
      }

      if(!name.show && !phone_country_code.show && phone_number.show) {
        const phoneRef:any = inst?.refs.phoneRef
        phoneRef.focus()
      }

      if(!name.show && !phone_country_code.show && !phone_number.show && lead_source.show) {
        const leadSourceRef:any = inst?.refs.leadSourceRef
        leadSourceRef.focus()
      }
    }

    const isShow = computed(() => {
      if([1, 7, 11, 15].includes(currentUser.value.role_id)) return true

      return false
    })

    const closeModal = (e) => {
      if(e.which === 27) {
        ql.isOpen = false
        resetFormValue()
      }
    }
    
    onBeforeMount(() => {
      window.addEventListener('keyup', closeModal);
    })

    const getQlEmail = computed(() => {
      return store.getters.getQlEmail
    })

     const getExistCustomer = computed(() => {
      return store.getters.getExistCustomer
    })

    const isViewed = computed(() => {
      return store.getters.getViewQuickLead;
    });
    const setViewQuickLead = () => {
      store.commit(Mutations.SET_VIEW_QUICK_LEAD, false);
    };

    const existingCustomer = ref()

    watchEffect(() => {
      if(isViewed.value)
      {
        MasterService.getCountryCodes();
        // UserService.getDomSalesAgentsManagerTeamLeader();
        MasterService.getLeadSources();
      }
      if(getExistCustomer.value) {
          existingCustomer.value.toFirst()
      }

      if(getQlEmail.value) {
          const emaiRef:any = inst?.refs.emaiRef
          emaiRef.focus()
          customRules.value.email.message = 'Email address already exist!'
          customRules.value.email.show = true

      }
      if(ql.isOpen && !getQlEmail.value) {
        setTimeout(() => {
        const nameRef:any = inst?.refs.nameRef
        nameRef.focus()
        }, 500);
      }
      
    })

    const qlDisable = computed(() => {
      return store.getters.getIsQLDisable
    })


    return {
      setViewQuickLead,
      isViewed,
      leadSourcesList,
      qlDisable,
      getExistCustomer,
      existingCustomer,
      getQlEmail,
      closeModal,
      ql,
      isShow,
      focusField,
      onLeadSourceClear,
      onLeadSourceChange,
      leadSources,
      currentUser,
      clearIt,
      redirectIt,
      checkPhoneNo,
      formData,
      submit,
      formRef,
      loading,
      addCustomerModalRef,
      resetFormValue,
      customRules,
      countryCodeList,
      onCountryCodeChange,
      countryCodes,
      route,
    };
  },
});
